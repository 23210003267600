import { SITE_URL } from "../../../../config/i18n";

export const data = {
  "/": `
    <script defer type="application/ld+json">
    [{
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Надстройка XLTools",
        "image": "${SITE_URL}/static/fa1d6577283a47545b9b52ddf180f6ff/xltools-add-in-logo.svg",
      "screenshot": "${SITE_URL}/static/d020bbaaf7fab47c91e3c69e2b3622f8/33964/xltools-add-in-for-excel-ribbon-ru.png",
        "applicationCategory": [
      "Программное обеспечение для продуктивности",
      "Надстройки Office"
      ],
      "url": "${SITE_URL}/ru/",
      "downloadUrl": "${SITE_URL}/ru/download-free-trial/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "Для MS Excel 2019, 2016, 2013, 2010, десктоп Office 365",
        "description": "XLTools — это мощная надстройка Excel для бизнес-пользователей, с которой ежедневная работа станет проще. Надстройка включает инструменты для подготовки данных, учета изменений и автоматизации задач в Excel.",
      "publisher": "ООО «ВейвПоинт»",
        "offers": {
         "@type": "AggregateOffer",
           "offerCount": "4",
           "lowPrice": "14.95",
           "highPrice": "199.95",
           "priceCurrency": "USD"
         },
          "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    },
    {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Надстройка XLTools",
      "parentOrganization": "ООО «ВейвПоинт»",
        "url": "${SITE_URL}/ru/",
        "logo": "${SITE_URL}/static/fa1d6577283a47545b9b52ddf180f6ff/xltools-add-in-logo.svg",
        "contactPoint" : [
            {
            "@type" : "ContactPoint",
            "contactType" : "Поддержка пользователей",
            "email": "support@xltools.net",
            "url": "${SITE_URL}/ru/support/"
            }
        ]
    }]
    </script>
    `,

  "/excel-popup-calendar-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Всплывающий Календарь, набор для Excel",
        "applicationSuite": "Надстройка XLTools",
        "image": "${SITE_URL}/static/9904bb65bd4833f41952f76aed3b4d15/fa4c2/popup-calendar-insert-date-time-in-excel-ru.png",
      "screenshot": "${SITE_URL}/static/d020bbaaf7fab47c91e3c69e2b3622f8/33964/xltools-add-in-for-excel-ribbon-ru.png",
        "applicationCategory": [
      "Программное обеспечение для продуктивности",
      "Надстройки Office"
      ],
        "url": "${SITE_URL}/ru/excel-popup-calendar-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "Для MS Excel 2019, 2016, 2013, 2010, десктоп Office 365",
        "description": "Всплывающий календарь для Excel - инструмент для легкой вставки дат и времени в ячейки.",
      "publisher": "ООО «ВейвПоинт»",
        "offers": {
          "@type": "Offer",
          "price": "14.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,

  "/excel-data-preparation-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Подготовка Данных, набор для Excel",
        "applicationSuite": "Надстройка XLTools",
        "image": "${SITE_URL}/static/23bc542fd80af4957ac07e289c593f54/fa4c2/data-prep-clean-cells-ru.png",
      "screenshot": "${SITE_URL}/static/d020bbaaf7fab47c91e3c69e2b3622f8/33964/xltools-add-in-for-excel-ribbon-ru.png",
        "applicationCategory": [
      "Программное обеспечение для продуктивности",
      "Надстройки Office"
      ],
        "url": "${SITE_URL}/ru/excel-data-preparation-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "Для MS Excel 2019, 2016, 2013, 2010, десктоп Office 365",
        "description": "Набор самых необходимых инструментов для очистки, форматирования, преобразования и подготовки данных Excel к анализу.",
      "publisher": "ООО «ВейвПоинт»",
        "offers": {
          "@type": "Offer",
          "price": "99.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,

  "/excel-automation-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Автоматизация, набор для Excel",
        "applicationSuite": "Надстройка XLTools",
        "image": "${SITE_URL}/static/97bc2a1969239276dc17b8ecd677d1a4/fa4c2/automation-write-commands-in-excel-table-ru.png",
      "screenshot": "${SITE_URL}/static/d020bbaaf7fab47c91e3c69e2b3622f8/33964/xltools-add-in-for-excel-ribbon-ru.png",
        "applicationCategory": [
      "Программное обеспечение для продуктивности",
      "Надстройки Office"
      ],
        "url": "${SITE_URL}/ru/excel-automation-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "Для MS Excel 2019, 2016, 2013, 2010, десктоп Office 365",
        "description": "Набор инструментов для автоматизации рутинных задач в Excel без VBA макросов.",
      "publisher": "ООО «ВейвПоинт»",
        "offers": {
          "@type": "Offer",
          "price": "199.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,

  "/excel-version-control-toolkit/": `
    <script defer type="application/ld+json">
    {
        "@context": "http://schema.org",
        "@type": "SoftwareApplication",
        "name": "Контроль Версий, набор для Excel",
        "applicationSuite": "Надстройка XLTools",
        "image": "${SITE_URL}/static/7f73c4edda4afaa5f79cee1ffabb1ece/fa4c2/version-control-compare-worksheet-versions-ru.png",
      "screenshot": "${SITE_URL}/static/d020bbaaf7fab47c91e3c69e2b3622f8/33964/xltools-add-in-for-excel-ribbon-ru.png",
        "applicationCategory": [
      "Программное обеспечение для продуктивности",
      "Надстройки Office"
      ],
        "url": "${SITE_URL}/ru/excel-version-control-toolkit/",
        "operatingSystem": "Windows 7 - 11",
        "permissions": "Для MS Excel 2019, 2016, 2013, 2010, десктоп Office 365",
        "description": "Набор инструментов для учета изменений в рабочих книгах Excel и VBA проектах.",
      "publisher": "ООО «ВейвПоинт»",
        "offers": {
          "@type": "Offer",
          "price": "149.95",
          "priceCurrency": "USD"
        },
        "sameAs" : [
            "https://www.facebook.com/XLTools",
            "https://www.linkedin.com/company/xltools-add-in/",
            "https://www.youtube.com/c/XLTools",
            "https://twitter.com/xltoolsen",
            "https://vk.com/xltools"
            ]
    }
    </script>
    `,
};
